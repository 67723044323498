<template>
<span v-if="information">  
  <span v-if="information.name == undefined">
    <span v-if="information == user.information.email">
      <i class="fal fa-user" v-if="!noIcon"></i> {{ user.information.name }}
    </span>
    <span v-if="information != user.information.email">
      <img v-if="!noIcon && showIsSefos" class="icon mr" src="/img/message_type/message.png" :alt="$t('SEFOS_USER')" />  {{ information }}
    </span>
  </span>
  <span v-if="information.item_type == 'message-fax'">
    <i class="fal fa-phone" v-if="!noIcon"></i> {{ information.email }}
  </span>
  <span v-if="information.item_type == 'addressbook'">
    <i class="fal fa-address-book" v-if="!noIcon"></i> {{ information.name }} <span v-if="!short">({{ information.email }}{{ information.phone }})</span>
  </span>
  <span v-if="information.item_type == 'graph'">
    <i class="fab fa-windows" v-if="!noIcon"></i> {{ information.name }} <span v-if="!short">(<span v-if="information.email">{{ information.email }}</span><span v-if="information.phone">{{ information.phone }}</span>)</span>
  </span>
  <span v-if="information.item_type == 'message-link'">
    <i class="fal fa-envelope" v-if="!noIcon && validateEmail(information.email)"></i
    ><i class="fal fa-phone" v-if="!noIcon && validatePhone(information.email)"></i>
    {{ information.email }}
  </span>
  <span v-if="information.item_type == 'message-user'">
    <img v-if="!noIcon" class="icon mr" src="/img/message_type/message.png" :alt="$t('SEFOS_USER')" />  {{ information.email }}
  </span>
  <span v-if="information.item_type == 'user'">
    <img v-if="!noIcon" class="icon mr" src="/img/message_type/message.png" :alt="$t('SEFOS_USER')" />  {{ information.name }}<span v-if="!short"><span v-if="information.title">, {{ information.title }}</span><span v-if="information.department">, {{ information.department }}</span>, {{ information.organisation }} ({{ information.email }})</span>
  </span>
  <span v-if="information.item_type == 'functionbox'">
    <img v-if="!(noIcon || isMeeting)" class="icon mr" src="/img/message_type/message.png" :alt="$t('FUNCTIONBOX.TITLE')" />  {{ information.name }}<span v-if="!short">,  {{ information.organisation }}({{ information.email }})</span>
  </span>
</span>
</template>
<script>
export default {
  components: {},
  props: {
    short: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isMeeting: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    noIcon: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    noLookup: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    identifier: {
      default: "",
      type: String,
    },
    item: {
      default: null,
      type: [Object, String],
    },
    message_type: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },    
    functionbox_uuid: {
      default: "",
      type: String,
    },  
    participants: {
      default: () => [],
      type: Array,
    }
  },
  data() {
    return {
      information: null
    }
  },
  methods: { 
    async init()
    {
      if(this.item != null)
      {
        this.information = this.item;
      }else{
        if(this.identifier != "" && !this.noLookup)
        {
          let exaktmatch = await this.$http.post(
              this.user.hostname + "/auto-complete/exaktmatch",
              {
                message_type: this.message_type,
                search: this.identifier,
                emails: [],
                user_uuid: this.user_uuid,
                functionbox_uuid: this.functionbox_uuid,
              });
          if (exaktmatch.data == null) {
            this.information = this.identifier;
          } else {
            if (exaktmatch.data.item_type == null) {
              this.information = this.identifier;
            } else {
              this.information = exaktmatch.data;
            }
          }
        }
      }
    },
  },
  computed: { 
    showIsSefos(){
      let information = this.information;
      return this.participants.some(participant => participant.email === information);
    }     
  },
  mounted() { 
    this.init();    
  },
};
</script>
<style></style>
